<template>
  <div class="page"><router-view></router-view></div>
</template>
<script>
export default {

}
</script>
<style lang="less">
</style>
